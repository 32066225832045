import * as React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const FieldSpacer = ({ grow, className, children }) => {
    return (
        <div
            className={`c-field-spacer ${
                grow ? `c-field-spacer--${grow}` : ''
            } ${className ? className : ''}`}
        >
            {children}
        </div>
    )
}

FieldSpacer.propTypes = {
    /**
     * Whether the element grows to fit its container
     */
    grow: PropTypes.oneOf(['stretch', 'half', 'third']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

FieldSpacer.defaultProps = {
    children: 'Place field here'
}

export default FieldSpacer
